import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Img from "gatsby-image"

export default function Thumb({ item }) {
  return (
    <Link to={`/rooms/${item.id}`}>
      <article className="roomthumb">
        <div className="roomthumb-img">
          <Img
            fluid={item.fotos[0].fluid}
            key={item.fotos[0].contentful_id}
            alt={item.fotos[0].description}
            style={{ height: "100%", width: "100%", zIndex: -2 }}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <div className="roomthumb-img-background"></div>
        <h2 className="roomthumb-title h2">{item.naam}</h2>
      </article>
    </Link>
  )
}
