import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import SEO from "../components/SeoComponent"

import Coffee from "../assets/coffee.svg"
import Double from "../assets/double.svg"
import Minibar from "../assets/minibar.svg"
import Tv from "../assets/tv.svg"
import Wifi from "../assets/wifi.svg"
import Bad from "../assets/bad.svg"
import PersonIcon from "../assets/person.svg"
import Person2Icon from "../assets/person2.svg"

import Img from "gatsby-image"

import Thumb from "../components/OtherRoomThumb/index"
import { useTranslation } from "react-i18next"

export default function RoomDetail(context) {
  //console.log(context.pageContext.room.node)

  const { t } = useTranslation()

  const allRooms = context?.pageContext?.allRooms?.edges

  if (typeof window !== "undefined") {
    document.body.style.overflow = "visible"
  }

  const {
    badkamer,
    id,
    naam,
    prijsDoubleweekDag,
    prijsDoubleweekend,
    prijsSingleweekDag,
    prijsSingleweekend,
    fotos,
  } = context.pageContext.room.node
  const { beschrijving } = context?.pageContext?.room?.node?.beschrijving
  const { soortKamer } = context?.pageContext?.room?.node?.soortKamer

  //Algorithm that defines which rooms to show
  let othersArray = []
  for (const element of allRooms) {
    //Don't show the room you're on in others rooms
    if (element.node.id !== id) {
      othersArray.push(element.node)
      if (othersArray.length === 4) {
        break
      }
    }
  }
  return (
    <Layout path="detail">
      <SEO title={`${naam}`} />
      <section className="detail">
        <div className="detail-img">
          <Img
            fluid={fotos[0].fluid}
            key={fotos[0].contentful_id}
            alt={fotos[0].description}
            style={{ height: "100%", width: "100%", zIndex: -2 }}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <div className="detail-content">
          <div className="detail-content-box">
            <p className="detail-category p-mulish">{t("detail.1")}</p>
            <h1 className="detail-title h2">
              {naam} {t("detail.2")}
            </h1>
          </div>
          <div className="detail-underscore"></div>
          <p className="detail-text"></p>
          <div className="detail-grid">
            <article className="detail-perks">
              <h2 className="hidden">Extra's</h2>
              <ul className="detail-list">
                {badkamer !== true || undefined || null ? (
                  ""
                ) : (
                  <li className="flex-horizontal">
                    <Bad />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("detail.13", {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    ></p>
                  </li>
                )}
                <li className="flex-horizontal">
                  <Wifi />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("detail.3", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </li>
                <li className="flex-horizontal">
                  <Coffee />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("detail.4", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </li>
                <li className="flex-horizontal">
                  <Double />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("detail.5", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </li>
                <li className="flex-horizontal">
                  <Tv />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("detail.6", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </li>
                <li className="flex-horizontal">
                  <Minibar />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("detail.7", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </li>
              </ul>
            </article>
            <article className="detail-prices">
              <h2 className="hidden">Prices</h2>
              <table className="table__prices">
                <tr>
                  <th className="hidden_record top"></th>
                  <th className="top left border-bottom">{t("detail.8")}</th>
                  <th className="top right border-left border-bottom">
                    {t("detail.9")}
                  </th>
                </tr>
                <tr>
                  {
                    //One person only
                  }
                  <td className="icon right border-bottom border-top">
                    <PersonIcon />
                  </td>
                  <td className="price">{prijsSingleweekDag}</td>
                  <td className="price border-left">{prijsSingleweekend}</td>
                </tr>
                <tr>
                  {
                    //Two person start here
                  }
                  <td className="icon right">
                    <Person2Icon />
                  </td>
                  <td className="price bottom border-right border-top">
                    {prijsDoubleweekDag}
                  </td>
                  <td className="price bottom border-top">
                    {prijsDoubleweekend}
                  </td>
                </tr>
              </table>
            </article>
          </div>
          <a
            className="button p-mulish button__detail--link"
            rel="noreferrer"
            target="_blank"
            href="https://www.booking.com/hotel/be/fleur-de-lys.nl.html?aid=1288280;label=metagha-link-localuniversalBE-hotel-249724_dev-desktop_los-1_bw-0_dow-Thursday_defdate-1_room-0_lang-nl_curr-EUR_gstadt-2_rateid-0_aud-0_cid-_gacid-6642513561_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20210325;sid=0b08409047913fca6e995e4d5fc38af2;checkin=2021-03-25;checkout=2021-03-26;dest_id=-1973154;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1616691131;srpvid=7de4769d0734014a;type=total;ucfs=1&#no_availability_msg"
          >
            {t("detail.10")}
          </a>
        </div>
      </section>
      <section className="detail-others">
        <h1 className="hidden">Other rooms</h1>
        {othersArray.map(item => {
          return <Thumb item={item} />
        })}
      </section>
      <section className="detail-contact">
        <h1 className="detail-contact-title">
          <p
            dangerouslySetInnerHTML={{
              __html: t("detail.11", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </h1>
        <Link to="/contact">{t("detail.12")}</Link>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulKamer {
      edges {
        node {
          badkamer
          beschrijving {
            beschrijving
          }
          prijsDoubleweekDag
          prijsDoubleweekend
          prijsSingleweekDag
          prijsSingleweekend
          naam
          fotos {
            description
            contentful_id
            resize {
              src
              height
              aspectRatio
              width
            }
          }
          id
        }
      }
    }
  }
`
